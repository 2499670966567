import "./src/styles/global.css";

document.addEventListener("DOMContentLoaded", () => {
    const buttons = document.querySelectorAll("[data-analytics-id]");

    buttons.forEach((button) => {
        button.addEventListener("click", (event) => {

        const target = event.target as HTMLElement;
        const analyticsId = target.getAttribute("data-analytics-id");

        if (analyticsId && typeof window.gtag === "function") {
            window.gtag("event", "click", {
            event_category: "Internal Navigation",
            event_label: analyticsId,
            });
        }
        });
    });
});
  
  